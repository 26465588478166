import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import blogs from "../../databases/Blogs";
import "./AllBlogs.css";
import { Helmet } from "react-helmet";
import HomeScreenImg from "../../components/media/images/SEOimages/Home Page.png";

const AllBlogs = () => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    setTimeout(() => {
      setBlogData(blogs);
      setLoading(false); // Set loading to false when data is loaded
    }, 1000); // Simulate 1 second loading time
  }, []);

  return (
    <div className="all-blogs__container">
      <Helmet>
        <title>Redwood Digital Strategies - Blogs</title>
        <meta name="description" content="A national digital marketing company specializing in lead generation and sales." />
        <meta name="keywords" content="digital marketing, lead generation, sales, online marketing, SEO, PPC, social media marketing, brand Strategies" />
        <meta name="robots" content="index, follow" /> {/* Robots meta tag */}
        <link rel="canonical" href="https://redwooddigitalstrategies.com/blogs" /> {/* Canonical URL */}
        <meta name="author" content="Redwood Digital Strategies" /> {/* Author */}
        <meta name="publisher" content="Redwood Digital Strategies" /> {/* Publisher */}
        <meta property="og:title" content="Redwood Digital Strategies - Blogs" />
        <meta property="og:description" content="A national digital marketing company specializing in lead generation and sales." />
        <meta property="og:image" content={HomeScreenImg} /> {/* Ensure HomeScreenImg is defined */}
        <meta property="og:url" content="https://redwooddigitalstrategies.com/" />
      </Helmet>

      {/* Banner section */}
      <header className="blogs-banner">
        <h1>Blogs</h1>
      </header>

      {/* Show loading text if still loading */}
      {loading ? (
        <div className="loading">
          <div className="loader">
            <div className="loading-text">
              Loading<span className="dot">.</span>
              <span className="dot">.</span>
              <span className="dot">.</span>
            </div>
            <div className="loading-bar-background">
              <div className="loading-bar">
                <div className="white-bars-container">
                  <div className="white-bar"></div>
                  <div className="white-bar"></div>
                  <div className="white-bar"></div>
                  <div className="white-bar"></div>
                  <div className="white-bar"></div>
                  <div className="white-bar"></div>
                  <div className="white-bar"></div>
                  <div className="white-bar"></div>
                  <div className="white-bar"></div>
                  <div className="white-bar"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="blogs-holder">
          <div className="blogs-container">
            {blogData.map((blog) => (
              <Link to={`/blog/${blog.slug}`} key={blog.slug} className="card-link">
                <div className="card">
                  <p className="card-title">{blog.title}</p>
                  <img className="card__image" alt={blog.title} src={blog.mainImage} />
                  <p className="card-body">{blog.subtitle.slice(0, 100)}...</p>
                  <button className="read-more cta-button__home">READ MORE</button>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllBlogs;
