import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import blogs from "../../databases/Blogs";
import "./SingleBlog.css";
import HomeScreenImg from "../../components/media/images/SEOimages/Home Page.png";

// Import the components
import SimpleText from "../../components/blog-types/SimpleText/SimpleText";
import LeftRightImageText from "../../components/blog-types/LeftRightImageText/LeftRightImageText";
import RightLeftImageText from "../../components/blog-types/rightLeftImageText/rightLeftImageText";
import Carousel from "../../components/blog-types/carousel/Carousel";
import JustImage from "../../components/blog-types/justImage/justImage";
import JustVideo from "../../components/blog-types/justVideo/justVideo";

const SingleBlog = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    // Find the blog by its slug
    const foundBlog = blogs.find((b) => b.slug === slug);
    setBlog(foundBlog);
  }, [slug]);

  if (!blog) {
    return <div>Blog post not found.</div>;
  }

  // Extract metadata for SEO
  const { metadata = {} } = blog; // Destructure metadata safely
  const keywords = metadata.keywords ? metadata.keywords.join(", ") : "blog, articles";

  return (
    <div>
      {/* SEO Metadata using Helmet */}
      <Helmet>
        <title>{blog.title} | Redwood Digital Strategies</title>
        <meta name="description" content={blog.subtitle || "Detailed blog on various topics"} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.subtitle} />
        <meta property="og:image" content={blog.mainImage} />
        <meta property="og:type" content="article" />
        <meta property="canonical" content={`https://redwooddigitalstrategies.com/blog/${blog.slug}`} />
        <meta property="og:url" content={`https://redwooddigitalstrategies.com/`} />
        <meta property="og:image" content={HomeScreenImg} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={blog.subtitle} />
        <meta name="twitter:image" content={blog.mainImage} />
      </Helmet>

      {/* Main Image and Title Banner */}
      <header className="blog-banner">
        <div className="blog-banner-overlay"></div>
        <img src={blog.mainImage} alt={blog.title} className="blog-banner-image" />
        <div className="blog-banner-content">
          <h1 className="blog-title">{blog.title}</h1>
          <p className="blog-subtitle">{blog.subtitle}</p>
        </div>
      </header>

      {/* Blog sections container */}
      <div className="blog-sections-container">
        {blog.sections.map((section, index) => {
          switch (section.type) {
            case "simpleText":
              return <SimpleText key={index} content={section.content} />;
            case "title": // Handle the new title section
              return (
                <h1 key={index} className="section-title">
                  {section.content}
                </h1>
              );
            case "leftRightImageText":
              return <LeftRightImageText key={index} image={section.image} text={section.text} video={section.video} title={section.title} />;
            case "rightLeftImageText":
              return <RightLeftImageText key={index} image={section.image} text={section.text} video={section.video} title={section.title} />;
            case "carousel":
              return <Carousel key={index} images={section.images} />;
            case "justImage":
              return <JustImage key={index} image={section.image} />;
            case "justVideo":
              return <JustVideo key={index} video={section.video} />;
            default:
              return null;
          }
        })}
      </div>

      {/* Footer Section */}
      <footer className="blog-footer">
        <p>
          Investing the time to understand the Statement of Activities is crucial for nonprofit leaders to fulfill their fiduciary responsibilities and guide their organizations towards sustainable
          success.
        </p>
        <p>
          Contact Know Your Processes today for expert guidance and support in mastering the intricacies of financial statements. Together, we can ensure your organization's financial health and
          stability.
        </p>
      </footer>
    </div>
  );
};

export default SingleBlog;
