import "./LeftRightImageText.css"
import React from 'react';
import ReactMarkdown from 'react-markdown'; 

const LeftRightImageText = ({ image, text, video, title }) => {
  return (
    <div className="left-right">
      {image && <img src={image} alt="Left" className="left-right-image" />}
      <div className="left-right-text">
        {title && <h3>{title}</h3>}
        {video ? (
          <video src={video} controls className="left-right-video" />
        ) : (
          <ReactMarkdown>{text}</ReactMarkdown>
        )}
      </div>
    </div>
  );
};

export default LeftRightImageText;

