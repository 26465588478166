import React from "react";
import "./CalendlyModal.css";
import CalendlyInlineWidget from "../CalendlyEmbed/CalendlyEmbed";

export default function CalendlyModal(props) {
  return (
    <div className={props.calendlyModal}>
      <p style={{ cursor: "pointer", color: "black", fontSize: "25px", fontWeight: "900", backgroundColor: "red", padding: "10px", borderRadius: "10%" }} onClick={props.candelyModalToggle}>
        X
      </p>
      <CalendlyInlineWidget />
    </div>
  );
}
