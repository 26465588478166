import React from "react";
import "./HomeTwo.css";
import { Helmet } from "react-helmet";
import HomeScreenImg from "../../../components/media/images/SEOimages/Home Page.png";
import HeroBanner from "../../../components/media/video/Redwoods Smaller.mov"; // Ensure the video path is correct
// import HeroBannerGiff from "../../../components/media/video/RedwoodsSmaller-ezgif.com-video-to-gif-converter.gif";
import Logo from "../../../components/media/images/Logos/LogoCutout.png";
import Services from "../../../components/Sections/Services";
// import CalendlyBadgeWidget from "../../../components/Calendly/CalendlyButton/CalendlyButton";

export default function HomeTwo(props) {
  const GotoNextSection = () => {
    const nextSection = document.getElementById("home-intro-section");
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>Redwood Digital Strategies - Home</title>
        <meta name="description" content="A national digital marketing company specializing in lead generation and sales." />
        <meta name="keywords" content="digital marketing, lead generation, sales, online marketing, SEO, PPC, social media marketing, brand Strategies" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://redwooddigitalstrategies.com/" />
        <meta name="author" content="Redwood Digital Strategies" />
        <meta name="publisher" content="Redwood Digital Strategies" />
        <meta property="og:title" content="Redwood Digital Strategies - Home" />
        <meta property="og:description" content="A national digital marketing company specializing in lead generation and sales." />
        <meta property="og:image" content={HomeScreenImg} />
        <meta property="og:url" content="https://redwooddigitalstrategies.com/" />
      </Helmet>

      {/* Hero Section */}
      <section className="hero-section">
        <img alt="Redwood Digital Marketing Strategies Logo" className="logo-image" src={Logo} />
        <video className="hero-video" src={HeroBanner} autoPlay muted loop />
        <div className="hero-overlay">
          <button onClick={props.candelyModalToggle} className="cta-button__home">
            Free Consultation
          </button>
          <div onClick={GotoNextSection} className="arrow-down">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="white">
              <path d="M12 16l-6-6h12l-6 6z" />
            </svg>
          </div>
        </div>
      </section>

      {/* Introduction Section */}
      <section id="home-intro-section" className="introduction-section">
        <div className="intro-content">
          <h2>Welcome to Redwood Digital Strategies</h2>
          <p>
            At Redwood Digital Strategies, we believe that growth is rooted in resilience and vision. With over a decade of experience, we help small to medium-sized businesses flourish through
            customized digital marketing strategies, ensuring your brand stands tall in the competitive forest of the online world.
          </p>
          <br />
          <br />
          <button onClick={props.candelyModalToggle} className="cta-button__home">
            Free Consultation
          </button>
          <h2>Our Approach, Your Growth</h2>
          <div className="values-container">
            <div className="value-item">
              <h3>Personal Touch</h3>
              <p>We prioritize building strong relationships with our clients to tailor strategies that align with their vision.</p>
            </div>
            <div className="value-item">
              <h3>Long-Term Growth</h3>
              <p>Our goal is to nurture your business for sustainable success, providing solutions that adapt as your brand evolves.</p>
            </div>
            <div className="value-item">
              <h3>Transparency</h3>
              <p>We ensure complete transparency in our processes and communications to foster trust and collaboration.</p>
            </div>
            <div className="value-item">
              <h3>Adaptability</h3>
              <p>In a constantly changing digital landscape, we stay agile and proactive to keep your business ahead of the curve.</p>
            </div>
          </div>
        </div>
      </section>
      <Services emailJSModelToggle={props.emailJSModelToggle} />
      {/* How We Work Section */}
      <section className="how-we-work-section">
        <div className="how-we-work-content">
          <p>
            We realize there isn’t a one size fits all approach to marketing. A dentist in Rochester NY is going to have different needs than a VR Formula One racing company in Austin TX. We should
            know, we work with both! At Redwood Digital Strategies, we grow beyond the canopy by unearthing data and driving growth.
          </p>
          <p>
            Rooted in our Strategies of building brands from the ground up, or nurturing brands to reach new heights, Redwood Digital Strategies cultivates strategies for evergreen success while
            nurturing brands to reach new heights.
          </p>
          <p>Finished with the puns? Schedule a 15-minute FREE Consultation today!</p>
          <button onClick={props.candelyModalToggle} className="cta-button__home">
            Free Consultation
          </button>
        </div>
      </section>
    </div>
  );
}
