import React from "react";
import "./WineryLP.css";
import { Helmet } from "react-helmet";
import HomeScreenImg from "../../../components/media/images/SEOimages/Home Page.png";

import DesktopVid from "../../../components/media/video/Low_qual_wine_scroll.mov";
import MobileVid from "../../../components/media/video/Low_qual_wine_scroll-ezgif.com-video-to-gif-converter.gif";

export default function WineryLP(props) {
  return (
    <div className="winery-lp">
      <Helmet>
        <title>About - Redwood Digital Strategies</title>
        <meta name="description" content="A national digital marketing company specializing in lead generation and sales for wineries in Napa and Sonoma." />
        <meta name="keywords" content="digital marketing, lead generation, sales, online marketing, SEO, PPC, social media marketing, brand Strategies" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://redwooddigitalstrategies.com/wineries" />
        <meta name="author" content="Redwood Digital Strategies Winery Ads" />
        <meta name="publisher" content="Redwood Digital Strategies" />
        <meta property="og:title" content="Winery Ads - Redwood Digital Strategies" />
        <meta property="og:description" content="A national digital marketing company specializing in lead generation and sales for wineries in Sonoma and Napa CA." />
        <meta property="og:image" content={HomeScreenImg} />
        <meta property="og:url" content="https://redwooddigitalstrategies.com/" />
      </Helmet>

      {/* Hero Section */}
      <section className="winery-hero-section">
        <video className="winery-hero-video" autoPlay loop muted playsInline src={DesktopVid}>
          <source src={MobileVid} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="winery-hero-overlay">
          <h1>Grow Your Winery's Online Presence with Redwood Digital Strategies</h1>
          <button onClick={props.candelyModalToggle} className="cta-button__home only-mobile">
            Schedule A Free Consultation
          </button>
          <p>Specializing in high-traffic wine websites and seamless integrations for Napa and Sonoma wineries.</p>
        </div>
      </section>

      {/* The Redwood Advantage */}
      <section className="advantage-section">
        <h2>The Redwood Advantage</h2>
        <p>
          At Redwood Digital Strategies, we understand the unique needs of wineries in Napa and Sonoma. From showcasing your story to driving online sales, our team has the expertise to create
          websites that captivate visitors and convert them into loyal customers.
        </p>
        <p>With over a decade of experience and a passion for helping businesses grow, we’re here to take your winery’s digital presence to the next level.</p>
        <button onClick={props.candelyModalToggle} className="cta-button__home">
          Schedule A Free Consultation
        </button>
      </section>

      {/* Seamless Integrations */}
      <section className="integrations-section">
        <h2>Smart Tools for Smarter Business</h2>
        <p>
          We specialize in integrating powerful winery management tools like s <strong> Commerce7</strong>, a platform designed to streamline your eCommerce, POS, and wine club operations.
        </p>
        <ul>
          <li>
            <strong>WineDirect:</strong> Boost your direct-to-consumer wine sales.
          </li>
          <li>
            <strong>VinoShipper:</strong> Simplify compliance and interstate shipping.
          </li>
          <li>
            <strong>ShipCompliant:</strong> Ensure accurate tax collection and reporting.
          </li>
          <li>
            <strong>Tock:</strong> Manage reservations for tastings, events, and tours.
          </li>
        </ul>
      </section>

      {/* Services Section */}
      <section className="services-section">
        <h2>How We Help Your Winery Thrive</h2>
        <div className="services-cards">
          <div className="service-card">
            <h3>Custom Website Design & Development</h3>
            <p>Create a stunning, high-performance website tailored to your winery’s brand.</p>
            <button onClick={() => props.emailJSModelToggle("Website Help")} className="cta-button">
              Website Help
            </button>
          </div>
          <div className="service-card">
            <h3>eCommerce Optimization</h3>
            <p>Simplify online wine sales with optimized shopping experiences and mobile-first design.</p>
            <button onClick={() => props.emailJSModelToggle("eComm Help")} className="cta-button">
              eComm Help
            </button>
          </div>
          <div className="service-card">
            <h3>Integrations</h3>
            <p>3rd party sales, marketing, and tracking installation support.</p>
            <button onClick={() => props.emailJSModelToggle("Integration Help")} className="cta-button">
              Integration Help
            </button>
          </div>
          <div className="service-card">
            <h3>SEO for Wineries</h3>
            <p>Attract wine enthusiasts near and far by appearing at the top of search results.</p>
            <button onClick={() => props.emailJSModelToggle("SEO Help")} className="cta-button">
              SEO Help
            </button>
          </div>
          <div className="service-card">
            <h3>Digital Marketing Strategy</h3>
            <p>Run effective PPC campaigns, social media ads, and email marketing to reach your ideal audience.</p>
            <button onClick={() => props.emailJSModelToggle("Marketing Help")} className="cta-button">
              Marketing Help
            </button>
          </div>
          <div className="service-card">
            <h3>Analytics & Tracking</h3>
            <p>Gain insights into your visitors’ behavior and track key performance metrics to fine-tune your strategy.</p>
            <button onClick={() => props.emailJSModelToggle("Analytics Help")} className="cta-button">
              Analytics Help
            </button>
          </div>
        </div>
      </section>

      {/* Success Stories Section */}
      <section className="success-section">
        <h2>Proven Results for Wineries</h2>
        <p>We’ve partnered with wineries in Napa and Sonoma to deliver:</p>
        <ul>
          <li>Increased website traffic by over 150%.</li>
          <li>Improved eCommerce sales by 70%.</li>
          <li>Seamless wine club management and boosted subscriptions.</li>
        </ul>
        <p>Let’s make your winery the next success story.</p>
        <button onClick={props.candelyModalToggle} className="cta-button__home">
          Schedule A Free Consultation
        </button>
      </section>
    </div>
  );
}
