import React from 'react';
import ReactMarkdown from 'react-markdown';
import "./SimpleText.css"

const SimpleText = ({ content }) => {
  return (
    <div className="simple-text">
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
};

export default SimpleText;
