import "./justImage.css"
import React from 'react';

const JustImage = ({ image }) => {
  return (
    <div className="just-image">
      <img src={image} alt="Centered" className="just-image-img" />
    </div>
  );
};

export default JustImage;
