import "./Carousel.css";
import React from "react";

const Carousel = ({ images }) => {
  return (
    <div className="carousel">
      {images.map((img, index) => (
        <img src={img} alt={`Carousel ${index}`} key={index} className="carousel-image" />
      ))}
    </div>
  );
};

export default Carousel;
