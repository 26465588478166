import React, { useState } from "react";
import "./App.css";
import MainContainer from "./containers/MainContainer";
import Layout from "./layouts/MainLayout.jsx";

function App() {
  const [desktopNavState, setDesktopNavState] = useState("hidden");
  const [calendlyModal, setCalendlyModal] = useState("calendly-modal-closed");
  const [emailJSModel, setEmailJSModal] = useState("emailjs-container-closed");
  const [formTitle, setFormTitle] = useState("");

  const emailJSModelToggle = (name) => {
    setFormTitle(name);
    // console.log("emailJSModelToggle", name);
    if (emailJSModel === "emailjs-container-closed") {
      setEmailJSModal("emailjs-container-opened");
    } else {
      setEmailJSModal("emailjs-container-closed");
    }
  };

  const desktopNavHandle = () => {
    // console.log("click", desktopNavState);
    if (desktopNavState === "hidden") {
      setDesktopNavState("visible");
    } else {
      setDesktopNavState("hidden");
    }
  };

  const candelyModalToggle = () => {
    if (calendlyModal === "calendly-modal-closed") {
      setCalendlyModal("calendly-modal-opened");
      // console.log("click", calendlyModal);
    } else {
      setCalendlyModal("calendly-modal-closed");
      // console.log("click", calendlyModal);
    }
  };

  return (
    <Layout formTitle={formTitle} emailJSModelToggle={emailJSModelToggle} emailJSModel={emailJSModel} calendlyModal={calendlyModal} candelyModalToggle={candelyModalToggle}>
      <MainContainer emailJSModelToggle={emailJSModelToggle} desktopNavState={desktopNavState} candelyModalToggle={candelyModalToggle} desktopNavHandle={desktopNavHandle} />
    </Layout>
  );
}

export default App;
