import React from "react";
import "./CaseStudy.css";
import ExLight from "../../components/media/images/CaseStudies/ezlight_roas.png";

export default function CaseStudy() {
  return (
    <div className="case-study-container">
      <h1 className="case-study-title">Case Study</h1>
      <h2 className="case-study-subtitle">Inside the Facebook Advertising Campaign for</h2>
      <h1 className="company-name">EZ Light Wraps</h1>
      <div className="roas">
        <p>
          <span className="roas-number">5.45</span> ROAS
        </p>
        <img className="full-width-img" alt="6 ROAS Marketing Bump" src={ExLight} />
      </div>

      <div className="case-study-section">
        <div className="section-header">Category:</div>
        <div className="section-content">eCommerce</div>
      </div>

      <div className="case-study-section">
        <div className="section-header">Product:</div>
        <div className="section-content">Light Covers</div>
      </div>

      <div className="case-study-section">
        <div className="section-header">Objectives:</div>
        <ul className="section-list">
          <li>Maximize sales of $40-$100 products.</li>
          <li>Generate a scalable stream of qualified leads.</li>
          <li>Maintain a constant flow of new customers while building lookalike audiences.</li>
        </ul>
      </div>

      <div className="case-study-section">
        <div className="section-header">Strategies:</div>
        <ul className="section-list">
          <li>Maximize revenue by running Facebook advertising campaigns targeting both warm and cold traffic.</li>
          <li>Generate audiences to scale for new customers.</li>
        </ul>
      </div>

      <div className="case-study-section">
        <div className="section-header">Tactics:</div>
        <ul className="section-list">
          <li>Drive traffic to a landing page and capture sales.</li>
          <li>Develop a Strategy to continuously gather information from current customers to build lookalike and new audiences.</li>
        </ul>
      </div>

      <div className="case-study-section">
        <div className="section-header">Execution:</div>
        <p className="section-paragraph">
          A proprietary process was used to define the target. The process began by identifying larger audiences with three main categories: landlords, renters, and homeowners.
        </p>
        <p className="section-paragraph">
          I was given $3,000 per month to try and get the best return possible, so I spent about a week on low-cost warming campaigns to test audiences. When we had what we thought would be a few good
          options, we started the sales campaign.
        </p>
        <p className="section-paragraph">Created, tested, and optimized ads based on a selection of variables including color, contrast, and headline message.</p>
        <p className="section-paragraph">High-performing campaigns were scaled up, and less effective campaigns were scaled down or eliminated.</p>
        <p className="section-paragraph">The landing page was optimized to look more like an eCommerce site, and we also tested some other landing pages along the way.</p>
      </div>

      <div className="case-study-section">
        <div className="section-header">Results:</div>
        <ul className="section-list">
          <li>Almost 500,000 impressions.</li>
          <li>CTR of 6.5%.</li>
          <li>ROAS of 5.45.</li>
        </ul>
      </div>

      <div className="case-study-section">
        <div className="section-header">Analysis:</div>
        <p className="section-paragraph">
          The most profitable traffic, which accounted for 63% of revenue, was warm traffic. This result reflects the Strategy of maximizing opportunities with strategically cultivated audiences to
          extend a relationship. This takes place by targeting people who have already expressed a degree of interest.
        </p>
        <p className="section-paragraph">Members of these warm audiences are drawn from sources including:</p>
        <ul className="section-list">
          <li>Uploaded email lists</li>
          <li>Website visitors</li>
          <li>Video viewers</li>
          <li>Facebook fans</li>
          <li>Landing page visitors</li>
        </ul>
      </div>
    </div>
  );
}
