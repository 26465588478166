import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import About from "../screens/About/About";
// import Home from "../screens/Home/Home";
import CaseStudy from "../screens/CaseStudy/CaseStudy";
import HomeTwo from "../screens/Home/HomeTwo/HomeTwo";
import WineryLP from "../screens/LandingPages/WineryLP/WineryLP";
import AllBlogs from "../screens/AllBllogs/AllBlogs";
import SingleBlog from "../screens/SingleBlog/SingleBlog";
import TestBlog from "../components/Tests/TestBlog/TestBlog";

export default function MainContainer(props) {
  return (
    <Routes>
      <Route path="/blog-test" element={<TestBlog />} />
      <Route path="/blogs" element={<AllBlogs />} />
      <Route path="/blog/:slug" element={<SingleBlog />} />
      <Route path="/winery" element={<WineryLP emailJSModelToggle={props.emailJSModelToggle} candelyModalToggle={props.candelyModalToggle} />} />
      <Route path="/wineries" element={<WineryLP emailJSModelToggle={props.emailJSModelToggle} candelyModalToggle={props.candelyModalToggle} />} />
      <Route path="/about" element={<About candelyModalToggle={props.candelyModalToggle} />} />
      <Route path="/case-study" element={<CaseStudy />} />
      <Route
        path="/"
        element={
          <HomeTwo emailJSModelToggle={props.emailJSModelToggle} candelyModalToggle={props.candelyModalToggle} desktopNavState={props.desktopNavState} desktopNavHandle={props.desktopNavHandle} />
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
