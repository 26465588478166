import React, { useEffect } from "react";

const CalendlyInlineWidget = () => {
  useEffect(() => {
    // Dynamically load the Calendly script
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div className="calendly-inline-widget" data-url="https://calendly.com/redwooddigitalstrategies-info/15min" style={{ minWidth: "320px", width: "50vw", height: "800px" }}></div>;
};

export default CalendlyInlineWidget;
