import React from "react";
import "./About.css";
import Bob from "../../components/media/images/Headshots/Bob-300x300.png";
import Hannah from "../../components/media/images/Headshots/Hannah-300x300.png";
import { Helmet } from "react-helmet";
import HomeScreenImg from "../../components/media/images/SEOimages/Home Page.png";

export default function About(props) {
  return (
    <div className="about-container">
      <Helmet>
        <title>About - Redwood Digital Strategies</title>
        <meta name="description" content="A national digital marketing company specializing in lead generation and sales." />
        <meta name="keywords" content="digital marketing, lead generation, sales, online marketing, SEO, PPC, social media marketing, brand Strategies" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://redwooddigitalstrategies.com/about" />
        <meta name="author" content="Redwood Digital Strategies" />
        <meta name="publisher" content="Redwood Digital Strategies" />
        <meta property="og:title" content="About - Redwood Digital Strategies" />
        <meta property="og:description" content="A national digital marketing company specializing in lead generation and sales." />
        <meta property="og:image" content={HomeScreenImg} />
        <meta property="og:url" content="https://redwooddigitalstrategies.com/" />
      </Helmet>

      {/* The Redwood Story Section */}
      <section className="redwood-story-section">
        <h1>The Redwood Story</h1>
        <p>
          Named after the majestic redwood trees, our company embodies strength, resilience, and the potential for regeneration. Just as fallen redwoods give rise to new life, we help businesses
          flourish, even after setbacks.
        </p>
      </section>

      {/* Mission Section */}
      <section className="our-mission-section">
        <div className="mission-content">
          <h2>Our Mission</h2>
          <h3>Empowering small and medium-sized businesses with customized digital marketing strategies that drive sustainable growth.</h3>
          <h2 className="our-values-title">Our Values</h2>
        </div>
        <div className="values-cards">
          <div className="notification">
            <div className="notiglow"></div>
            <div className="notiborderglow"></div>
            <div className="notititle">Commitment</div>
            <div className="notibody">We build strong, lasting relationships.</div>
          </div>
          <div className="notification">
            <div className="notiglow"></div>
            <div className="notiborderglow"></div>
            <div className="notititle">Integrity</div>
            <div className="notibody">We believe in honest and transparent marketing practices.</div>
          </div>
          <div className="notification">
            <div className="notiglow"></div>
            <div className="notiborderglow"></div>
            <div className="notititle">Growth-Oriented</div>
            <div className="notibody">We focus on strategies that ensure continuous improvement and success.</div>
          </div>
        </div>
      </section>

      {/* Meet the Team Section */}
      <section className="team-section">
        <h2>Meet the Team</h2>
        <div className="card-holder">
          <div className="card">
            <img className="headshot-img" src={Bob} alt="Bob's Headshot" />
            <div className="card-content">
              <h3>Bob</h3>
              <p>
                Bob is a software engineer and tracking specialist. With extensive experience in digital marketing and data analytics, he ensures seamless integration of tracking solutions for
                clients. Bob's technical expertise and innovative strategies empower businesses to optimize their digital footprints.
              </p>
              <p>
                Bob has been deemed "The Unicorn of Digital Marketing" because of his vast knowledge of tracking and analytics, website design, audience building, and client relations. It doesn't hurt
                that he has worked in over ten industries doing everything from Tech support, to Financial Advisor, Opera Singer, Dental Assistant, Uber Driver, Search and Rescue Leader and so on...
              </p>
              <p>"At the end of the day, my favorite thing is to see a happy client. There is no better feeling than boosting someone up higher than they ever could have imagined."</p>
            </div>
          </div>
          <div className="card">
            <img className="headshot-img" src={Hannah} alt="Hannah's Headshot" />
            <div className="card-content">
              <h3>Hannah</h3>
              <p>
                Hannah is a client relations and acquisition manager with a knack for building lasting partnerships. Her experience in digital marketing makes her a key player in managing client
                interactions, driving client satisfaction, and spearheading strategic growth initiatives.
              </p>
              <p>
                Hannah's passion for effective communication and strategic thinking allows her to connect deeply with clients and ensure their marketing strategies align with their business goals.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* How We Work Section */}
      <section className="how-we-work-section">
        <h2>How We Work</h2>
        <div className="how-we-work__text">
          <p>
            We realize there isn’t a one size fits all approach to marketing. A dentist in Rochester NY is going to have different needs than a VR Formula One racing company in Austin TX. We should
            know, we work with both! At Redwood Digital Strategies, we grow beyond the canopy by unearthing data and driving growth.
          </p>
          <p>
            Rooted in our Strategies of building brands from the ground up, or nurturing brands to reach new heights, Redwood Digital Strategies cultivates strategies for evergreen success while
            nurturing brands to reach new heights.
          </p>
          <p>Finished with the puns? Schedule a 15-minute FREE Consultation today!</p>
        </div>
        <button onClick={props.candelyModalToggle} className="cta-button">
          Free Consultation
        </button>
      </section>
    </div>
  );
}
