import React from "react";
import Footer from "../components/Footer/Footer";
import CalendlyModal from "../components/Calendly/CalendlyModal/CalendlyModal";
import EmailJS from "../components/EmailJS/EmailJS";

export default function Layout(props) {
  return (
    <div>
      <EmailJS formTitle={props.formTitle} emailJSModelToggle={props.emailJSModelToggle} emailJSModel={props.emailJSModel} />
      <CalendlyModal candelyModalToggle={props.candelyModalToggle} calendlyModal={props.calendlyModal} />
      <main>{props.children}</main>
      <Footer emailJSModelToggle={props.emailJSModelToggle} />
    </div>
  );
}
