import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import CryptoJS from "crypto-js";
import "./EmailJS.css";

export default function EmailJS(props) {
  const form = useRef();

  const hashEmail = (email) => {
    return CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const emailInput = form.current.elements["reply_to"].value; // Access the email input
    const hashedEmail = hashEmail(emailInput);

    // Push to Data Layer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "emailSubmission",
      hashedEmail: hashedEmail,
      title: props.formTitle,
    });

    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY).then(
      (result) => {
        console.log(result.text);
        // window.location.reload();
        // alert("Thanks, we'll be in touch!");
      },
      (error) => {
        console.log(error.text);
      }
    );

    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID_2, form.current, process.env.REACT_APP_PUBLIC_KEY).then(
      (result) => {
        console.log(result.text);
        window.location.reload();
        alert("Thanks, we'll be in touch!");
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <div className={props.emailJSModel}>
      <div className="form-wrapper">
        <h1 className="emailjs-header">{props.formTitle}</h1>
        <form ref={form} onSubmit={sendEmail}>
          <input type="hidden" name="form_title" value={props.formTitle} />
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="from_name" required className="form-input" />

          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="reply_to" required className="form-input" />

          <label htmlFor="message">Message</label>
          <textarea id="message" name="message" required className="form-textarea"></textarea>

          <input type="submit" value="Send" className="form-submit-button" />
        </form>
        <button className="form-exit-button" onClick={() => props.emailJSModelToggle("")}>
          Exit
        </button>
      </div>
    </div>
  );
}
