import "./justVideo.css"
import React from 'react';

const JustVideo = ({ video }) => {
  return (
    <div className="just-video">
      <video src={video} controls className="just-video-vid" />
    </div>
  );
};

export default JustVideo;
