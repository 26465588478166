import React from "react";
import "./Footer.css";
import Logo from "../../components/media/images/Logos/LogoCutout.png";

import { Link } from "react-router-dom";

export default function Footer(props) {
  return (
    <div className="footer__container">
      <div>
        <Link to="/" className="nav-link">
          <h3>Home</h3>
        </Link>
        <Link to="/about" className="nav-link">
          <h3>About</h3>
        </Link>
        <Link to="/case-study" className="nav-link">
          {" "}
          <h3>Case Studies</h3>
        </Link>

        <h3 className="nav-link" onClick={() => props.emailJSModelToggle("Our Services")}>
          Contact
        </h3>
      </div>
      <div>
        <Link to="/" className="nav-link">
          <img alt="Redwood Digital Marketing Strategies Logo" className="logo-cutout-footer" src={Logo} />
        </Link>
      </div>
    </div>
  );
}

// <Link to="/blogs" className="nav-link">
//           {" "}
//           <h3>Blogs</h3>
//         </Link>
