import "./rightLeftImageText.css"
import React from 'react';
import ReactMarkdown from 'react-markdown'; 
const RightLeftImageText = ({ image, text, video, title }) => {
  return (
    <div className="right-left">
      <div className="right-left-text">
        {title && <h3>{title}</h3>}
        {video ? (
          <video src={video} controls className="right-left-video" />
        ) : (
          <ReactMarkdown>{text}</ReactMarkdown>
        )}
      </div>
      {image && <img src={image} alt="Right" className="right-left-image" />}
    </div>
  );
};

export default RightLeftImageText;
