const blogs = [
  {
    id: 1,
    title: "The Facebook Ad Mistakes Killing Your Conversions",
    subtitle: "Avoid these common pitfalls to improve your Facebook Ads ROI.",
    mainImage: "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1728171052/Mark%20Andersen/Woman_Happy_Saving_CoputerAdobeStock_647874703_gsgmgb.jpg",
    sections: [
      {
        type: "title",
        content: "Are Your Facebook Ads Underperforming?",
      },
      {
        type: "leftRightImageText",
        image: "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1728171049/Mark%20Andersen/Investments_AdobeStock_460475199_xjeh0y.jpg",
        text: "If your Facebook ads aren’t delivering the conversions you need, chances are you're making common mistakes. Smart targeting, creative content, and proper strategy can turn things around. Let's uncover what might be going wrong — and how you can fix it.",
      },
      {
        type: "title",
        content: "Mistake 1: Casting Too Wide a Net",
      },
      {
        type: "rightLeftImageText",
        image: "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1728171049/Mark%20Andersen/Hapy_Business_PeopleAdobeStock_263767541_iojjlm.jpg",
        text: "Trying to reach everyone might feel like a solid strategy, but it’s actually a conversion killer. Facebook ad targeting works best when you narrow down your audience. Instead of wasting budget on broad demographics, focus on people who are genuinely interested in your product.",
      },
      {
        type: "title",
        content: "Mistake 2: Ignoring Retargeting Opportunities",
      },
      {
        type: "leftRightImageText",
        image: "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1728171045/Mark%20Andersen/Stamp_on_Paperwork_AdobeStock_306643131_jtabih.jpg",
        text: "Neglecting Custom Audiences means you’re missing out on potential customers who have already interacted with your brand. Retargeting these users can significantly boost conversions.",
      },
      {
        type: "title",
        content: "Mistake 3: Failing to Leverage Lookalike Audiences",
      },
      {
        type: "rightLeftImageText",
        image: "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1728171036/Mark%20Andersen/Assets_LiabilitiesAdobeStock_862043283_mhinvi.jpg",
        text: "If you’re not using Lookalike Audiences, you’re leaving money on the table. This powerful tool helps you find new potential customers who share traits with your existing audience.",
      },
      {
        type: "title",
        content: "Mistake 4: Using Poor Ad Relevance",
      },
      {
        type: "leftRightImageText",
        image: "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1728171034/Mark%20Andersen/Digital_Advertising_AdobeStock_298627676_qtxb7e.jpg",
        text: "Facebook ranks ads based on their relevance to the audience. Ads with poor quality, low engagement, or ineffective targeting will result in wasted ad spend and low conversions.",
      },
      {
        type: "title",
        content: "Mistake 5: Overlooking Recent Shoppers",
      },
      {
        type: "rightLeftImageText",
        image: "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1728171042/Mark%20Andersen/Woman_Meeting_Paperwork_AdobeStock_303330374_ncdfea.jpg",
        text: "Did you know you can target people who have recently made purchases from Facebook ads? Ignoring this behavior is a missed opportunity for high-intent shoppers.",
      },
      {
        type: "title",
        content: "Mistake 6: Not Repurposing High-Performing Content",
      },
      {
        type: "leftRightImageText",
        image: "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1728171049/Mark%20Andersen/Investments_AdobeStock_460475199_xjeh0y.jpg",
        text: "You might already have unicorn content—posts or blogs that perform exceptionally well. Failing to repurpose this content as Facebook ads means you’re not maximizing its potential.",
      },
      {
        type: "title",
        content: "How We Can Help Fix These Mistakes",
      },
      {
        type: "rightLeftImageText",
        image: "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1728171052/Mark%20Andersen/Woman_Happy_Saving_CoputerAdobeStock_647874703_gsgmgb.jpg",
        text: "At Redwood Digital Strategies, we specialize in helping businesses transform their Facebook ad strategies. From refining your audience targeting to creating ad campaigns that convert, we’re here to make sure your ad spend generates maximum ROI.",
      },
    ],
    metadata: {
      keywords: ["Facebook Ads", "Digital Marketing", "Ad Strategy", "Audience Targeting", "Lookalike Audiences", "ROI"],
    },
    slug: "facebook-ad-mistakes",
  },
];

export default blogs;
