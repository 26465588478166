import React from "react";
import "./Services.css";

export default function Services(props) {
  return (
    <div>
      {/* What We Can Do For You Section */}
      <section className="services-section">
        <h2>What We Can Do For You</h2>
        <div className="services-cards">
          <div className="service-card">
            <h3>Website Build</h3>
            <p>From Scratch (Any builder or HTML), Touch Ups for better User Experience, High Performing Funnel Creation, Mobile Optimization</p>
            <ul>
              <li>Full Custom Builds</li>
              <li>User Experience Enhancements</li>
              <li>High Performing Funnel Creation</li>
              <li>Mobile Optimization & Responsive Design</li>
            </ul>
            <button onClick={() => props.emailJSModelToggle("Website Help")} className="cta-button">
              Website Help
            </button>
          </div>

          <div className="service-card">
            <h3>Digital Marketing Audit</h3>
            <p>Analyze current strategies, identify strengths and weaknesses, and offer actionable recommendations for growth.</p>
            <li>Detailed Performance Analysis</li>
            <li>Competitive Benchmarking</li>
            <li>Channel Effectiveness</li>
            <li>Custom Action Plan</li>
            <li>Audience Insights</li>
            <button onClick={() => props.emailJSModelToggle("Marketing Audit")} className="cta-button">
              Marketing Audit
            </button>
          </div>

          <div className="service-card">
            <h3>Widget / Website Code Add-On</h3>
            <p>If we have access to your APIs, we can build custom tools, features, and integrations tailored to your specific needs, enhancing user engagement and functionality.</p>
            <button onClick={() => props.emailJSModelToggle("Widget Add On")} className="cta-button">
              Widget Add On
            </button>
          </div>

          <div className="service-card">
            <h3>Tracking & Analytics Setup / Audit</h3>
            <p>
              Ensure your tracking and analytics are set up correctly for accurate data collection. We'll audit existing systems or establish new ones, including custom event tracking, Google Tag
              Manager, and advanced reporting solutions.
            </p>
            <button onClick={() => props.emailJSModelToggle("Tracking Help")} className="cta-button">
              Tracking Help
            </button>
          </div>

          <div className="service-card">
            <h3>Branding</h3>
            <p>
              Develop a cohesive brand identity that resonates with your audience. This includes logo design, color schemes, and voice/tone development to create a strong and memorable brand presence.
            </p>
            <li>Logo and Visual Identity</li>
            <li>Color Palette and Typography</li>
            <li>Brand Voice and Messaging</li>
            <button onClick={() => props.emailJSModelToggle("Branding")} className="cta-button">
              Branding
            </button>
          </div>

          <div className="service-card">
            <h3>Comprehensive Digital Marketing Solutions</h3>
            <p>
              We dive deep into understanding your company, aligning branding with marketing strategies, and building warming campaigns, sales funnels, and PPC campaigns. We focus on sustainable
              growth and a data-driven approach to maximize ROI.
            </p>
            <button onClick={() => props.emailJSModelToggle("Free Consultation")} className="cta-button">
              Free Consultation
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
